/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;1,100;1,300&display=swap");
:root {
  --orange: #ff9100;
  --orangeL: #ff2d00;
  --orangeP:#F16012;
  --deg: linear-gradient(90deg, var(--orangeL), var(--orange));
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
 font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1d273b;
  font-weight: 300;
}


@media screen and (max-width:800px) {
  .titulo {
      font-size: 45px;
  }

  .contenido-header {
      flex-direction: column;
      justify-content: space-evenly;
      height: 720px;
  }

  .contenido-header>img {
      width: 400px;
  }

  .textos-header {
      margin: 0;
  }

  /* About us */

  .articulo {
      width: 45%;
  }

  /* Questions */

  .questions {
      height: 720px;
      justify-content: space-evenly;
      flex-direction: column;
  }

  .questions>img {
      width: 400px;
  }

  /* results */

  .conten-results {
      flex-direction: column-reverse;
  }

  .results-text>a {
      margin-bottom: 60px;
  }

  .numbers {
      width: 100%;
      /* justify-content: space-between; */
  }

  .results-text {
      width: 100%;
  }

  /* Services */

  .services {
      flex-direction: column-reverse;
      align-items: center;
  }

  .box-skills {
      margin: 0 0 40px 0;

  }

  .box-skills h4 {
      width: 500px;
  }

  .services>img {
      width: 400px;
  }

  /* Footer */

  footer {
      justify-content: space-evenly;
  }

  .partFooter {
      width: 40%;
      margin-bottom: 40px;
  }
}

@media screen and (max-width:600px) {
  .titulo {
      font-size: 35px;
  }

  h1 {
      font-size: 40px;
  }

  .contenido-header {
      height: 650px;
  }

  .nav {
      padding: 0 10px;
  }

  .contenido-header>img {
      width: 200px;
  }

  .enlaces-header {
      position: fixed;
      background: #667db6;
      /* fallback for old browsers */
      background: -webkit-linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);
      /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(to right, #667db6, #0082c8, #0082c8, #667db6);
      /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-evenly;
      clip-path: circle(0.0% at 100% 0);
  }

  .nav .menudos {

      -webkit-clip-path: circle(150% at 100% 0);
      clip-path: circle(150% at 100% 0);

  }

  .nav .enlaces-header a {
      color: #fff;
  }

  .hamburguer {
      display: block;
  }

  /* About us */

  .articulo {
      width: 95%;
  }

  /* Questions */

  .questions>img {
      width: 200px;
  }

  /* results */

  .numbers .number {
      width: 95%;
  }

  .results-text h4 {
      font-size: 35px;
  }

  /* Services */

  .services>img {
      width: 200px;
  }

  .box-skills h4 {
      width: 100%;
  }

  /* Footer */
  .partFooter {
      width: 95%;
      margin-bottom: 40px;
  }
}
.error {
    color: #e74c3e;
    background: rgba(255, 0, 0, 0.1);
    text-align: center;
    border-radius: 3px;
    border:  1px solid #ef9a9a;
    font-size: 15px;
    margin-top: 3px;
    padding: 5px 8px;
    width: auto;
    ;
}